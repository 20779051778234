<button
  [class]="btnClass"
  [ngClass]="ngcls"
  (click)="onButtonClick()"
  class="common-btn"
  [type]="type"
  [disabled]="disabled"
  [title]="disabled ? disableText : ''"
>
  <ng-content></ng-content>
  {{ name | translate }}
</button>
