import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input({ required: true }) name!: string;
  @Input() btnClass!: string;
  @Input() ngcls!: string;
  @Input() type: string = 'button';
  @Input() disableText: string = '';
  @Input() disabled: boolean = false;
  @Output() onClick: any = new EventEmitter();

  onButtonClick = () => {
    this.onClick.emit();
  };
}
